import React, {useEffect, useState} from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {routesBase} from "v4/data/appRoutes"
import {Link, Redirect} from "react-router-dom"
import useFetch from "v4/hooks/useFetch"
import apiService from "v4/services/api.service"
import {OUTLOOK_SUBSCRIPTION_CONFIGURATION_ADD, OUTLOOK_SUBSCRIPTION_CONFIGURATION_ITEM} from "v4/data/apiRoutes";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import Loader from "v4/components/ui/Loader/Loader";
import useNotification from "v4/hooks/useNotification";
import {PolIcon} from "components/PolIcon/policon";

export default function OutlookSubscriptionConfigurationsForm({customerId, id}) {
    const {t} = usePolTranslation()
    const {addSuccessNotification, addErrorNotification} = useNotification()
    const [{data: outlookSubscriptionConfigurationData, isLoading, isFinished}, fetchOutlookSubscriptionConfiguration] = useFetch()
    const [{
        data: outlookSubscriptionConfigurationDataSave,
        errorData: submitErrors,
        isError: isErrorSave,
        isLoading: isLoadingSave,
        isFinished: isFinishedSave,
    }, fetchOutlookSubscriptionConfigurationSave] = useFetch()
    const [outlookSubscriptionConfiguration, setOutlookSubscriptionConfiguration] = useState({
        label: null,
        folder: null,
        configuration: null,
        customerId: customerId
    })
    const isEdit = (!!id)
    const [redirect, setRedirect] = useState(null)

    useEffect(() => {
        if (isEdit) {
            fetchOutlookSubscriptionConfiguration({
                url: apiService.generateUrl(OUTLOOK_SUBSCRIPTION_CONFIGURATION_ITEM, {id: id}),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (outlookSubscriptionConfigurationData && isFinished) {
            setOutlookSubscriptionConfiguration(outlookSubscriptionConfigurationData)
        }
    }, [outlookSubscriptionConfigurationData, isFinished])

    const handleChange = (input) => {
        setOutlookSubscriptionConfiguration({...outlookSubscriptionConfiguration, [input.target.name]: input.target.value})
    }

    const handleSave = (e) => {
        e.preventDefault()
        fetchOutlookSubscriptionConfigurationSave({
            url: isEdit
                ? apiService.generateUrl(OUTLOOK_SUBSCRIPTION_CONFIGURATION_ITEM, {id: id})
                : apiService.generateUrl(OUTLOOK_SUBSCRIPTION_CONFIGURATION_ADD),
            config: {
                method: 'POST',
                headers: {
                    Accept: 'application/ld+json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({...outlookSubscriptionConfiguration, customer: customerId})
            }
        })
    }

    useEffect(() => {
        if (isErrorSave && isFinishedSave) {
            addErrorNotification(t('outlook_subscription_configuration_save_error'))
        }

        if (!isErrorSave && isFinishedSave) {
            addSuccessNotification(t('outlook_subscription_configuration_save_done'))
            setRedirect(<Redirect to={routesBase.adminMicrosoftGraph}/>)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outlookSubscriptionConfigurationDataSave, isErrorSave, isFinishedSave])

    const CardHeaderTools = () => (
        <>
            <Link to={routesBase.adminMicrosoftGraph} className="btn btn-secondary">{t('retour')}</Link>
            <button type="button" onClick={handleSave} className="btn btn-primary">{t('sauvegarder')}</button>
        </>
    )

    return (
        <AdminCard title={t('manage_outlook_subscription_configurations')} CardHeaderTools={CardHeaderTools}>
            {
                (isLoading || isLoadingSave) ? <Loader message="Chargement..."/> : null
            }
            <form className="form form__scrollable form__admin" onSubmit={handleSave}>
                <div className="row row-fluid mt-4">
                    <div className={'col-6 ' + (isErrorSave && ' field-error ')}>
                        <div className={'col-12 field ' + (outlookSubscriptionConfiguration?.label ? 'field-written' : null)}>
                            {
                                submitErrors?.data?.errors?.find(e => e.key === 'label')?.messages.map(e => (
                                    <div className={'form__error'}
                                        onMouseOver={(event) => event.currentTarget.classList.add('error-hover')}
                                        onMouseLeave={(event) => event.currentTarget.classList.remove('error-hover')}
                                    >
                                        <PolIcon icon={'exclamation-circle'} className={'form__error__icon'}/>
                                        <p className={'form__error__content'}>{t(e)}</p>
                                    </div>
                                ))
                            }
                            <input type="text" name="label" id="outlook_subscription_configuration_label" className="field__input"
                                   defaultValue={outlookSubscriptionConfiguration?.label} onChange={(input) => handleChange(input)}/>
                            <label className="field__label" htmlFor="outlook_subscription_configuration_label">{t('outlook_subscription_configuration_label')} *</label>
                        </div>



                        <div className={'col-12 field ' + (outlookSubscriptionConfiguration?.folder ? 'field-written' : null)}>
                            {
                                submitErrors?.data?.errors?.find(e => e.key === 'folder')?.messages.map(e => (
                                    <div className={'form__error'}
                                         onMouseOver={(event) => event.currentTarget.classList.add('error-hover')}
                                         onMouseLeave={(event) => event.currentTarget.classList.remove('error-hover')}
                                    >
                                        <PolIcon icon={'exclamation-circle'} className={'form__error__icon'}/>
                                        <p className={'form__error__content'}>{t(e)}</p>
                                    </div>
                                ))
                            }

                            <input type="text" name="folder" id="outlook_subscription_configuration_folder" className="field__input"
                                   defaultValue={outlookSubscriptionConfiguration?.folder} onChange={(input) => handleChange(input)}/>
                            <label className="field__label" htmlFor="outlook_subscription_configuration_folder">{t('outlook_subscription_configuration_folder')}</label>
                        </div>

                        <div className={'col-12 field ' + (outlookSubscriptionConfiguration?.configuration ? 'field-written' : null)}>
                            {
                                submitErrors?.data?.errors?.find(e => e.key === 'configuration')?.messages.map(e => (
                                    <div className={'form__error'}
                                         onMouseOver={(event) => event.currentTarget.classList.add('error-hover')}
                                         onMouseLeave={(event) => event.currentTarget.classList.remove('error-hover')}
                                    >
                                        <PolIcon icon={'exclamation-circle'} className={'form__error__icon'}/>
                                        <p className={'form__error__content'}>{t(e)}</p>
                                    </div>
                                ))
                            }
                            <textarea name="configuration" id="outlook_subscription_configuration_configuration" className="field__textarea" rows={16}
                                      defaultValue={outlookSubscriptionConfiguration?.configuration} onChange={(input) => handleChange(input)}></textarea>
                            <label className="field__label" htmlFor="outlook_subscription_configuration_configuration">{t('outlook_subscription_configuration_configuration')}</label>
                        </div>
                    </div>
                    <div className={'col-6 '}>
                        <div>
                            <b>{t('outlook_configuration_available_options')} :</b>
                            <ul>
                                <li>contact_search: from (default) / to</li>
                                <li>debug: true / false</li>
                            </ul>
                            <br/>

                            <b>{t('configuration_tokens_available')} :</b>
                            <ul>
                                <li>%customerId%</li>
                                <li>%userId%</li>
                                <li>%tenantId%</li>
                                <li>%subscriptionId%</li>
                                <li>%mail.id%</li>
                                <li>%mail.createdAt%</li>
                                <li>%mail.subject%</li>
                                <li>%mail.body%</li>
                                <li>%mail.from.name%</li>
                                <li>%mail.from.email%</li>
                                <li>%mail.hasAttachments%</li>
                                <br/>

                                <b>{t('for_example')} : </b>
                                <li>%date|value|format%</li>
                                <ul>
                                    <li>%date|first day of February next year|d-m-Y%</li>
                                    <li>%date|yesterday|d-m-Y h:i%</li>
                                    <li>%date|now|d/m/Y%</li>
                                    <li>{t('warning_task_field_date_must_be')}</li>
                                    <li>Y-m-d\TH:i : {t('for_example')} => %date|now|Y-m-d\TH:i%</li>
                                </ul>
                            </ul>
                        </div>
                        <br/>
                        <div>
                            <b>{t('remember_task_type_name_begin_at_are_required')}</b>
                        </div>
                    </div>
                </div>
            </form>
            {redirect}
        </AdminCard>
    )
}
